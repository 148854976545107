<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "@/components/widgets/stat.vue";

import BasicInfoTab from './components/tab_basic_info.vue'
import StateLogTab from './components/tab_state_log.vue'
import BreakdownTab from './components/tab_statement.vue'
import StatementTab from './components/tab_statement_records.vue'
import FolderTab from './components/tab_folder.vue'

import DepositRentDialog from './components/deposit_rent_dialog.vue'
import AutoPostFlagDialog from './components/edit_auto_post_flag_dialog.vue'
import UpdateTenantPaymentTypeDialog from './components/update_tenant_payment_dialog.vue'
import UpdateBtoPersonDialog from './components/update_bto_person_dialog.vue'
import UpdateAddressDialog from './components/update_rta_address_dialog.vue'

import appConfig from "@/app.config";
import { getPMApi } from '@/api/pm'

/**
 * Project-overview component
 */
export default {
  page: {
    title: "RTA Detail",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "RTA Detail",
      items: [
        {
          text: "RTA",
          href: "/",
        },
        {
          text: "RTA List",
          href: "/pm/property_list",
        },
        {
          text: 'Detail',
          active: true,
        },

      ],

      property: {


      },

      widgetData: [
        {
          icon: "uil-dollar-alt",
          title: "Available Balance",
          value: '$2123.34',
        },
        {
          icon: "uil-dollar-alt",
          title: "Deposit",
          value: '$10234.3',
        },
        {
          icon: "uil-dollar-alt",
          title: "Rent in Hold",
          value: '$1234.3',
        },
      ],

      tenant_list: [],
      owner_list: []


    };
  },
  components: {
    Layout,
    PageHeader,
    Stat,

    BasicInfoTab,
    BreakdownTab,
    StateLogTab,
    StatementTab,
    FolderTab,


    DepositRentDialog,
    AutoPostFlagDialog,
    UpdateTenantPaymentTypeDialog,
    UpdateBtoPersonDialog,
    UpdateAddressDialog


  },

  computed: {
    /**
     * Total no. of records
     */
    pm_code() {
      return this.$route.query.pm_id
    },
  },

  methods: {

    fileDownload(f) {
      window.open(f.save_route, '_blank');
    },

    pm_deposit_rent(rent_info) {
      console.log(rent_info)
    },



    add_expense() {
      this.$router.push({ name: 'pm-batch-expense', query: { property_id: this.property.property_id } })
    },

    post_ap() {
      this.$router.push({ name: 'pm-property-batch-pm-ap', query: { property_id: this.property.property_id } })
    },

    deposit_rent(t) {
      if (t == 'PAD') {
        this.$router.push({ name: 'pm-rent-deposit-pad', query: { property_id: this.property.property_id } })
      } else if (t == 'Cheque') {
        this.$router.push({ name: 'pm-rent-deposit-cheque', query: { property_id: this.property.property_id } })
      } else if (t == 'ETransfer') {
        this.$router.push({ name: 'pm-rent-deposit-etransfer', query: { property_id: this.property.property_id } })
      }
    },

    onDeleteTenant() {

    },

    ononTenantUpdate() {

    }


  },



  created() {


    getPMApi().detail({ pm_id: this.$route.query.pm_id }).then((res) => {

      if (res.errCode == 0) {
        this.property = res.data
        this.tenant_list = this.property.tenants
        this.owner_list = this.property.landlords

        this.widgetData[0].value = this.property.balance_available ? this.property.balance_available.toLocaleString() : 0
        this.widgetData[1].value = this.property.deposit ? this.property.deposit.toLocaleString() : 0
        this.widgetData[2].value = this.property.rent_in_hold ? this.property.rent_in_hold.toLocaleString() : 0

      }

    })

  },
  mounted() {

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <b-modal centerd id="modal-deposit-rent-dialog" title="Deposit Rent" size="lg" hide-footer>
      <DepositRentDialog :property="property" @cancel="$bvModal.hide('modal-deposit-rent-dialog')"
        @confirm="pm_deposit_rent" />
    </b-modal>
    <!-- -->

    <b-modal centerd id="modal-auto-post-dialog" title="Auto Post" size="lg" hide-footer>
      <AutoPostFlagDialog :property="property" @cancel="$bvModal.hide('modal-auto-post-dialog')"
        @confirm="$bvModal.hide('modal-auto-post-dialog')" />
    </b-modal>
    <!-- Auto Post Flag Dialog-->


    <b-modal centerd id="tenant_edit_dialog" title="Tenant" size="lg" hide-footer>
      <UpdateTenantPaymentTypeDialog :tenant_list="tenant_list" :property="this.property" @confirm="ononTenantUpdate"
        @cancel="$bvModal.hide('tenant_edit_dialog')" @onDelete="onDeleteTenant" />
    </b-modal>
    <!-- tenant -->

    <b-modal centerd id="bto_person_edit_dialog" title="BTO" size="lg" hide-footer>
      <UpdateBtoPersonDialog :landlord_list="owner_list" :property="this.property" @confirm="ononTenantUpdate"
        @cancel="$bvModal.hide('bto_person_edit_dialog')" />
    </b-modal>
    <!-- BTO -->


    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-0">
          <div class="card-body pb-5">
            
            <div class="row">
              <div class="col">
                <div class="d-flex align-items-center mb-3">
                  <div class="flex-shrink-0">
                    <div class="avatar">
                      <div class="avatar-title bg-soft-primary text-primary font-size-18 rounded">
                        PM
                      </div>
                    </div>
                  </div>
                  <div class=" ms-4">
                    <div class="text-muted">
                      <h5 class="font-size-16 mb-2">{{ property.property_id }}</h5>
                      <h5 class="font-size-16 mb-2">{{ property.address }}</h5>

                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->

              <div class="col-auto">
                <div class="d-flex flex-wrap align-items-start justify-content-md-end gap-2 mb-3">


                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->



            <b-modal centerd id="address_edit_dialog" title="Address" size="lg" hide-footer>
              <UpdateAddressDialog :property="this.property" @confirm="ononTenantUpdate"
                @cancel="$bvModal.hide('address_edit_dialog')" />
            </b-modal>
            <!-- Address -->

            <Stat :widgetData="widgetData" />

            <div class="row mb-3">

              <div class="col-md-12 mb-3  me-3">
                <div class="d-flex flex-wrap align-items-start   gap-4">


                  <b-dropdown variant="soft-primary">
                    <template slot="button-content">
                      Deposit Rent
                      <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item @click="deposit_rent('PAD')">PAD</b-dropdown-item>
                    <b-dropdown-item @click="deposit_rent('Cheque')">Cheque</b-dropdown-item>
                    <b-dropdown-item @click="deposit_rent('ETransfer')">ETransfer</b-dropdown-item>
                  </b-dropdown>
                  <b-button class="btn btn-soft-primary" @click="post_ap">Post AP</b-button>
                  <b-button class="btn btn-soft-primary" @click="add_expense">Add Expense</b-button>


                  <b-dropdown text="More" variant="soft-secondary">
                    <template slot="button-content">
                      <i class="fa fa-tag"></i>
                      <span style="margin-left:8px; margin-right: 8px;">More</span>
                      <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item type="button"
                      @click="$router.push({ name: 'pm-property-deposit-release', query: { pm_id: property.property_id } })">Deposit
                      Release</b-dropdown-item>
                    <b-dropdown-item type="button" @click="$bvModal.show('tenant_edit_dialog')">Edit
                      Tenant</b-dropdown-item>
                    <b-dropdown-item type="button" @click="$bvModal.show('bto_person_edit_dialog')">Edit EFT
                      Person</b-dropdown-item>
                    <b-dropdown-item type="button" @click="$bvModal.show('address_edit_dialog')">Edit
                      Address</b-dropdown-item>
                    <b-dropdown-item type="button" @click="$bvModal.show('modal-auto-post-dialog')">Enable Auto
                      Post</b-dropdown-item>
                  </b-dropdown>
                  <!-- end dropdown -->

                </div>
              </div>
            </div>




        <b-tabs class="mt-n5 pt-5" content-class="mt-4" nav-class="nav-tabs-custom"
          nav-wrapper-class="nav-tabs-custom">

          <b-tab title="RTA Info">
            <BasicInfoTab :property="property" />
          </b-tab>

          <b-tab title="Break Down">
            <BreakdownTab :property="property" />
          </b-tab>


          <b-tab title="Statement">
            <StatementTab :property="property" />
          </b-tab>
          <b-tab title="Files">
            <FolderTab />
          </b-tab>


          <b-tab title="State Log">
            <StateLogTab :property="property" />
          </b-tab>



        </b-tabs>
        <!-- Nav tabs -->
      </div>
          <!-- end card body -->
        </div>
        <!-- en card -->

      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
