<script>


/**
 * Property Info TAB component
 */

 import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import {getPMApi} from '@/api/pm'
import { financial } from '@/api/misc'
import SplitRentDialog from './split_rent_detail_dialog.vue'
import Choices from "choices.js";


export default {

  emits: ['confirm', 'cancel'],

  props: ['property'],
  components: {
    SplitRentDialog,
    flatPickr
  },

  data() {

    return {
      rent_obj : {
        pm_id  : '',
        rent   : 0
      },

      nsf_description : '',
      nsf_date        : '',
      r_trans_date    : '',
      r_trans_description : '',
      r_trans_amount  : '',
      expense_sub_type: '',
      expense_note    : '',
      current_expense : {},
      year_options    : [],

      selected_year   : '',

      nsf_obj : {

      },

      trans_obj :  {

      }
    }

  },


  methods: {

    f(v, d = 2) {
      return financial(v, d)
    },

    onReverse(rent, type) {
      this.nsf_obj.rent = rent
      this.nsf_obj.type = type
      this.$bvModal.show('nsf_dialog')
      
    },

    onConfirmNsf() {
      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to "+this.nsf_obj.type+" this Transaction?",
        () => {
          getPMApi().reverse_deposit({pm_id : this.property.pm_id,deposit_id : this.nsf_obj.rent.rent_id, pm_month: this.nsf_obj.rent.pm_month, post_date : this.nsf_date, description : this.nsf_description}).then(res => {
            if (res.errCode == 0) {
              this.$alertify.error(" Reverse Success" );
              this.$bvModal.hide('nsf_dialog')
              this.$router.go()
            } else {
              this.$alertify.error("Failed to Update " + res.msg);
            }
          })
        })
      
    },
    


    onRevise(obj, data, item) {
      
      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to Cancel this AP?",
        () => {
          getPMApi().cancel_ap_record({pay_out_id : item.payout_id}).then(res => {
            if (res.errCode == 0) {
              obj.statement.list.splice(data.index, 1)
            
            } else {
              this.$alertify.error("Failed to Cancel ret:" + res.errCode);
            }
          })
        })
      
    },

    onReverseTrans(payout_obj) {
      console.log(payout_obj)
      this.trans_obj.payout_obj = payout_obj
      this.r_trans_amount       = payout_obj.amount
      this.$bvModal.show('reverse_transaction_dialog')
    },


    onConfirmReverseTrans() {
    
      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to Reverse this Transaction?",
        () => {
          getPMApi().reverse_ap_transaction({pm_id : this.property.pm_id,pay_out_id : this.trans_obj.payout_obj.payout_id,  post_date : this.r_trans_date, note : this.r_trans_description}).then(res => {
            if (res.errCode == 0) {
              this.$alertify.message("Reversed successfully" );
              this.$bvModal.hide('reverse_transaction_dialog')
              this.$router.go()
            } else {
              this.$alertify.error("Failed to Update " + res.msg);
            }
          })
        })

    },

    onSplitRent(data) {
     
      this.rent_obj.rent    = data.amount
      this.rent_obj.pm_id   = this.property.property_id
      this.rent_obj.rent_id = data.rent_id
      this.$bvModal.show('split_rent_dialog')

    },


    onUpdateExpenseType(data) {
      this.expense_sub_type = data.sub_type
      this.expense_note     = data.remarks
      this.current_expense  = data
      this.$bvModal.show('expense_type_update')
    },

    onConfirmUpdateExpenseType() {
      let data = {
        expense_id   : this.current_expense.payout_id,
        pm_id        : this.property.property_id,
        sub_type     : this.expense_sub_type,
        remarks      : this.expense_note
      }
      getPMApi().update_expense_type(data).then(res => {
        if (res.errCode == 0) {
          this.current_expense.type = `Expense(${this.expense_sub_type})-${ this.current_expense.invoice}`
          this.$bvModal.hide('expense_type_update')
          this.$alertify.message(" Updated" );
        } else {
          this.$alertify.error("Failed to update expense type ret:" + res.errCode);
        }
      })
    },

    onYearChanged() {
      getPMApi().query_pm_rent_detail({property_id : this.property.property_id, year : this.selected_year}).then(res => {
        if (res.errCode == 0) {
          this.property.month_group_list = res.data
        } else {
          this.$alertify.error("Failed to query:" + res.errCode);
        }
      })
    }

  },



  created() {

  },

  mounted() {
    let year = new Date().getFullYear()
    this.selected_year = year
    new Choices('#expense_sub_type', { itemSelectText: '' })

    this.year_options = [
      { value: year, text: year },
      { value: year - 1, text: year - 1 },
      { value: year - 2, text: year - 2 },
      { value: year - 3, text: year - 3 }
    ]
   // new Choices('#year_selection', {position : 'bottom', choices: [{value : year, label : new String(year), selected: true}, {value : year -1, label : new String( year -1)}, {value : year - 2, label :  new String(year -2)}, {value : year-3, label :  new String(year-3)}]})
  },


};
</script>

<template>
  <div class="row">
    
    <b-modal centerd id="split_rent_dialog" :title="'Split Property Rent ' "  size="lg" hide-footer>
      <SplitRentDialog   :property="rent_obj"  @confirm="$router.go()" @cancel="$bvModal.hide('split_rent_dialog')"/>
    </b-modal>




    <b-modal centerd id="nsf_dialog" :title="'Reverse Deposit' "  size="lx" hide-footer>
      <div class="row mb-3">
        <div class="col-md-4">
          <label>Post Date</label>
          <flat-pickr  v-model="nsf_date" placeholder="Select a date"  class="form-control" ></flat-pickr>
        </div>
        <div class="col-md-8">
          <label>Description</label>
          <input type="text" class="form-control" v-model="nsf_description"/>
        </div>
      </div>
      <div class="row">
        <div  class="d-flex flex-wrap gap-2 col-md-3"></div>
        <div  class="d-flex flex-wrap gap-2 col-md-6">
          <b-button variant="danger" @click="onConfirmNsf" >Confirm</b-button>
          <b-button variant="primary" @click="$bvModal.hide('nsf_dialog')" >Cancel</b-button>
        </div>
      </div>
    </b-modal>
    <!-- NFS dialog -->


    <b-modal centerd id="reverse_transaction_dialog" :title="'Reverse Transaction' "  size="lg" hide-footer>
      <div class="row mb-3">
        <div class="col-md-2">
          <label>Amount</label>
          <input type="text" class="form-control" v-model="r_trans_amount" readonly/>
        </div>
        <div class="col-md-2">
          <label>Post Date</label>
          <flat-pickr  v-model="r_trans_date" placeholder="Select a date"  class="form-control" ></flat-pickr>
        </div>
        <div class="col-md-8">
          <label>Description</label>
          <input type="text" class="form-control" v-model="r_trans_description"/>
        </div>
      </div>
      <div class="row">
        <div  class="d-flex flex-wrap gap-2 col-md-4"></div>
        <div  class="d-flex flex-wrap gap-2 col-md-6">
          <b-button variant="danger" @click="onConfirmReverseTrans" >Confirm</b-button>
          <b-button variant="primary" @click="$bvModal.hide('reverse_transaction_dialog')" >Cancel</b-button>
        </div>
      </div>
    </b-modal>
    <!-- Reverse Transaction dialog -->

    <b-modal centerd id="expense_type_update" :title="'Expense Type Update ' "  size="lg" hide-footer :static="true">
      <div class="row mb-3">
        <div class="col-md-4">
          <label class="form-label" for="gen-info-name-input">Expense Type</label>
            <select id="expense_sub_type" class="form-control" v-model="expense_sub_type" >
              <option value="NSF">NSF</option>
              <option value="Repair">Repair</option>
              <option value="Insurance">Insurance</option>
              <option value="Cleaning">Cleaning</option>
              <option value="Maintaince">Maintaince</option>
              <option value="Other">Other</option>
            </select>
        </div>
        <div class="col-md-6">
          <label class="form-label" for="gen-info-name-input">Expense Note</label>
          <input type="text" class="form-control" v-model="expense_note" />
        </div>

        <div class="col-md-2 mt-4">
          <b-button variant="primary" @click="onConfirmUpdateExpenseType" >Update</b-button>
        </div>

      </div>
    </b-modal>


    <div data-simplebar>

      <div class="row">
        <div class="col-md-2">
          <div class="d-flex gap-2 mb-3  form-floating">
            
            <b-form-select id="year_selection" class="form-select" :options="year_options" v-model="selected_year" @change="onYearChanged">
            </b-form-select>
            <label class="form-label" for="year_selection">Year Selection</label>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <div class="table align-middle table-nowrap">
          <b-table :items="property.month_group_list" :fields="['ID', 'Month',  'Rent',  'Expense', 'NR4', 'MgtFee','Fee', 'Landlord']"
          thead-class="bg-light" :head-variant="'light'" hover responsive="sm" :per-page="50" class="table-check">

            <template #cell(ID)="row">
              <feather :type="row.detailsShowing ? 'chevron-down' : 'chevron-right'" @click="row.toggleDetails"></feather>
            </template>

            <template #cell(Month)="data">
              {{ data.item.month }} {{ data.item.preivew ? '(Preview Month)' : '' }}
            </template>
            <template #cell(Rent)="data">
              ${{ f(data.item.statement.rent).toLocaleString() }}
            </template>
          
            <template #cell(Expense)="data">
              ${{ f(data.item.statement.expense).toLocaleString() }}
            </template>
            <template #cell(NR4)="data">
              ${{ f(data.item.statement.nr4).toLocaleString() }}
            </template>
            <template #cell(MgtFee)="data">
              ${{ f(data.item.statement.mgt_fee).toLocaleString() }}
            </template>
            
            <template #cell(Landlord)="data">
              ${{ f(data.item.statement.owner).toLocaleString() }}
            </template>

            <template v-slot:row-details="row">
              <div class="col-md-12 "><label class="d-flex justify-content-center">{{row.item.month +'-Details'}}</label></div>
              <div class="table align-middle table-nowrap row">
                <div class="col-md-2"></div>
                <b-table :items="row.item.statement.list ? row.item.statement.list : []"
                  :fields="[{key : 'A', label:'',tdClass: 'align-middle'},{ key: 'Type' ,tdClass: 'align-middle'}, { key: 'Date' ,tdClass: 'align-middle'}, { key: 'Amount',tdClass: 'align-middle'},{key : 'Action',tdClass: 'align-middle'}]"
                  thead-class="bg-light" :head-variant="'light'" hover responsive="sm" :per-page="row.item.statement.list ? row.item.statement.list.length : 0"
                  class="table-check  col-md-8">

                  <template #cell(Type)="data">
                    {{ data.item.type }}
                  </template>

                  <template #cell(Date)="data">
                    {{ data.item.post_date }}
                  </template>

                  <template #cell(Amount)="data">
                    ${{ f(data.item.total).toLocaleString() }}
                  </template>

                  <template #cell(Action)="data">
                    <b-dropdown
                      variant="white"
                      right
                      toggle-class="btn-link text-dark shadow-none"
                    >
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item @click="onRevise(row.item, data, data.item)" :disabled="data.item.cash_type !='AP' || data.item.is_finished=='1'">Cancel AP</b-dropdown-item>
                      <b-dropdown-item @click="onReverse(data.item, 'REVERSE')" :disabled="data.item.cash_type !='DE' || data.item.is_reversed == '1'">Reverse</b-dropdown-item>
                      <b-dropdown-item @click="onSplitRent(data.item)" :disabled="data.item.cash_type !='DE'  || data.item.is_reversed == '1'">Split Rent</b-dropdown-item>
                      <b-dropdown-item @click="onUpdateExpenseType(data.item)" :disabled="data.item.cash_type !='AP' || data.item.is_expense == false">Update Expense Type</b-dropdown-item>
                    
                      </b-dropdown>

                  </template>

                </b-table>
              </div>
            </template>

          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>