<script>


/**
 * Property Info TAB component
 */

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import { getPMApi } from '@/api/pm'
import { required } from "vuelidate/lib/validators";
import { financial } from '@/api/misc'

export default {

  emits: ['confirm', 'cancel'],

  props: ['property'],
  components: {
    flatPickr
  },

  data() {

    return {
      start_date: '',
      end_date: '',
      statement: {
        ending_balance: 0,
        cash_flow: []
      },

      queryed: false,

      enable_description: '0'
    }

  },

  validations: {
    start_date: { required },
    end_date: { required },
  },
  methods: {


    f(n, d = 2) {
      return financial(n, d)
    },

    query_sstatement() {

      this.$v.$touch()
      if (this.$v.$invalid == true) {
        return;
      }
      let data = {
        pm_id: this.property.property_id,
        start_date: this.start_date,
        end_date: this.end_date,

      }
      getPMApi().query_pm_statement(data).then(res => {
        if (res.errCode == 0) {
          this.queryed = true
          this.statement = res.data

          this.statement.deposit_list = []
          this.statement.owner_list = []
          this.statement.expenses_list = []
          this.statement.mgt_fee = []
          this.statement.placement_fee = []
          this.statement.cash_flow.map(e => {
            if (e.cash_type == 'Rent' || e.cash_type == 'Owner Reserved' || e.cash_type == 'Utilities' || e.cash_type == 'Secure Deposit' || e.cash_type == 'Other') {
              this.statement.deposit_list.push(e)
            } else if (e.cash_type == 'Owner' || e.cash_type == 'Deposit Release') {
              this.statement.owner_list.push(e)
            } else if (e.cash_type == 'Mgt Fee' || e.cash_type == 'Placement Fee') {
              this.statement.mgt_fee.push(e)
            } else {
              this.statement.expenses_list.push(e)
            }
          })
        } else {
          this.queryed = false
        }
      })

    },

    download_pdf() {
      let data = {
        pm_id: this.property.property_id,
        start_date: this.start_date,
        end_date: this.end_date,
        enable_description: this.enable_description == '1' ? true : false
      }

      getPMApi().download_pm_statement(data).then(res => {
        if (res.errCode == 0) {
          window.open(res.url, '_blank')
        }

      })

    }
  },

  created() {

  },

  mounted() {

  },


};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">

      <form class="needs-validation" @submit.prevent="formSubmit">
      <div class="row">
        <div class="col-md-2">
          <div class="mb-3  form-floating">
            <flat-pickr v-model="start_date" placeholder="Select a date" class="form-control" id="start_date"
              :class="{ 'is-invalid': $v.start_date.$error }"></flat-pickr>
            <div v-if="$v.start_date.$error" class="invalid-feedback">
              <span v-if="!$v.start_date.required">This value is required.</span>
            </div>
            <label class="form-label" for="start_date">Start Date</label>
          </div>
        </div>
        <div class="col-md-2">
          <div class="mb-3  form-floating">
            <flat-pickr v-model="end_date" placeholder="Select a date" class="form-control" id="end_date"
              :class="{ 'is-invalid': $v.end_date.$error }"></flat-pickr>
              <label class="form-label" for="end_date">End Date</label>
            <div v-if="$v.end_date.$error" class="invalid-feedback">
              <span v-if="!$v.end_date.required">This value is required.</span>
              
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="mb-3  form-floating">
            <b-form-select id="st_description" class="form-select" v-model="enable_description">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </b-form-select>
            <label class="form-label" for="st_description" >Description Flag</label>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="mb-3">
            <b-button variant="primary" @click="query_sstatement">Query</b-button>
          </div>
        </div>
      </div>

      </form>

      <div class="card">
        <div class="card-body">

          <div class="invoice-title">
            <div class="text-muted">
              <h4 class="float-end font-size-15">
                <span class="badge bg-success font-size-12 ms-2"></span>
              </h4>
              <div class="row">
                <div class="col-md-6">
                  <div class="text-end ">Property:</div>
                </div>
                <div class="col-md-6">
                  <label>{{ property.property_id }}</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="text-end">Address:</div>
                </div>
                <div class="col-md-6">
                  <label>{{ property.address }}</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="text-end">Date:</div>
                </div>
                <div class="col-md-6">
                  <label>{{ start_date }} - {{ end_date }}</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="text-end">Ending Balance:</div>
                </div>
                <div class="col-md-6">
                  <label>${{ f(statement.ending_balance).toLocaleString() }}</label>
                </div>
              </div>
            </div>
          </div>



          <div class="py-2">
            <div class="pt-3"></div>
            <div class="pt-3"></div>
            <h5 class="font-size-15">Deposit Detail</h5>
            <hr class="my" />
            <div class="table-responsive">
              <table class="table align-middle table-borderless">
                <tbody>
                  <tr v-for="(item, idx) in statement.deposit_list" :key="'cash_flow_deposit_' + idx">
                    <th scope="row">{{ idx + 1 }}</th>
                    <td class="borderless">
                      <div>
                        <h5 class="text-truncate font-size-14 mb-1">{{ item.post_date }} </h5>
                        <p class="text-muted mb-0"></p>
                      </div>
                    </td>
                    <th scope="row">{{ item.remarks }}</th>
                    <td class="text-end">${{ f(item.total).toLocaleString() }}</td>
                  </tr>
                  <!-- end tr -->
                </tbody>
                <!-- end tbody -->
              </table>
              <!-- end table -->
            </div>

            <div class="pt-3"></div>
            <div class="pt-3"></div>
            <h5 class="font-size-15">Expense Detail</h5>
            <hr class="my" />
            <div>
              <table class="table align-middle ">
                <tbody>
                  <tr v-for="(item, idx) in statement.expenses_list" :key="'cash_flow_deposit_' + idx">
                    <th scope="row">{{ idx + 1 }}</th>
                    <td>
                      <div>
                        <h5 class="text-truncate font-size-14 mb-1">{{ item.post_date }} </h5>
                        <p class="text-muted mb-0"></p>
                      </div>
                    </td>
                    <th scope="row">{{ item.remarks }}</th>
                    <td class="text-end" style="width:10dvh">${{ f(item.amount).toLocaleString() }}</td>
                    <td class="text-end" style="width:10dvh">${{ f(item.gst).toLocaleString() }}</td>
                    <td class="text-end" style="width:10dvh">${{ f(item.total).toLocaleString() }}</td>
                  </tr>
                  <!-- end tr -->
                </tbody>
                <!-- end tbody -->
              </table>
              <!-- end table -->
            </div>



            <div class="pt-3"></div>
            <div class="pt-3"></div>
            <h5 class="font-size-15">Mgt Fee</h5>
            <hr class="my" />
            <div>
              <table class="table align-middle ">
                <tbody>
                  <tr v-for="(item, idx) in statement.mgt_fee" :key="'cash_flow_mgt_fee_' + idx">
                    <th scope="row">{{ idx + 1 }}</th>
                    <td>
                      <div>
                        <h5 class="text-truncate font-size-14 mb-1">{{ item.post_date }} </h5>
                        <p class="text-muted mb-0"></p>
                      </div>
                    </td>
                    <th scope="row">{{ item.remarks }}</th>
                    <td class="text-end" style="width:10dvh">${{ f(item.amount).toLocaleString() }}</td>
                    <td class="text-end" style="width:10dvh">${{ f(item.gst).toLocaleString() }}</td>
                    <td class="text-end" style="width:10dvh">${{ f(item.total).toLocaleString() }}</td>
                  </tr>
                  <!-- end tr -->
                </tbody>
                <!-- end tbody -->
              </table>
              <!-- end table -->
            </div>

            <div class="pt-3"></div>
            <div class="pt-3"></div>
            <h5 class="font-size-15">Balance To Owner Detail</h5>
            <hr class="my" />
            <div class="table-responsive">
              <table class="table align-middle ">
                <tbody>
                  <tr v-for="(item, idx) in statement.owner_list" :key="'cash_flow_deposit_' + idx">
                    <th scope="row">{{ idx + 1 }}</th>
                    <td>
                      <div>
                        <h5 class="text-truncate font-size-14 mb-1">{{ item.post_date }} </h5>
                        <p class="text-muted mb-0"></p>
                      </div>
                    </td>
                    <th scope="row">{{ item.remarks }}</th>
                    <td class="text-end">${{ f(item.total).toLocaleString() }}</td>
                  </tr>
                  <!-- end tr -->
                </tbody>
                <!-- end tbody -->
              </table>
              <!-- end table -->
            </div>
            <!-- end table responsive -->

          </div>
        </div>


      </div>
      <!-- end card -->
      <div class="d-print-none mt-4">
        <div class="col-md-4">
          <b-button variant="primary" @click="download_pdf" v-if="queryed">Export Pdf</b-button>
        </div>
      </div>

    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>