<script>


/**
 * Property Info TAB component
 */


export default {

    emits: ['confirm', 'cancel'],

    props: ['property'],
    components: {

    },

    data() {

        return {

        }

    },
    methods: {


    },

    created() {

    },

    mounted() {

    },


};
</script>

<template>
   
   <div class="row">
              <div class="col-lg-4">
                <div class="mt-3">
                  <ul class="text-muted">
                    <li class="py-1">Available Balance : {{
                      property.balance_available ? property.balance_available.toLocaleString()
                      :0 }}</li>
                    <li class="py-1">Secure Deposit : {{ property.deposit ? property.deposit.toLocaleString() : 0 }}</li>
                    <li class="py-1">Rent in Hold : {{ property.rent_in_hold ? property.rent_in_hold.toLocaleString() : 0
                      }}</li>

                    <li class="py-1" v-for="(a, idx) in property.agents" :key="a.id + '_' + idx">Agent : {{ a.name }}</li>
                    <li class="py-1" v-for="(a, idx) in property.landlords" :key="a.id + '_' + idx">Landlord : {{
                      a.landlord_name }} {{
                      a.last_name }}</li>
                    <li class="py-1" v-for="(a, idx) in property.tenants" :key="a.id + '_' + idx">Tenant : {{ a.name }}</li>
                    <li class="py-1">BTO Name: {{ property.owner_name }}</li>
                  </ul>
                </div>
              </div>
              <!-- end col -->

              <div class="col-lg-7">
                <div class="row">
                  <div class="col-lg-3 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i class="uil uil-check-circle text-primary font-size-22"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Rent</h5>
                        <p class="text-muted mb-0">${{ Number(property.monthly_rent).toLocaleString() }}</p>
                      </div>
                    </div>
                  </div>


                  <div class="col-lg-3 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i class="uil uil-check-circle text-primary font-size-22"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">BTO Type</h5>
                        <p class="text-muted mb-0">{{ property.l_payment }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i class="uil uil-check-circle text-primary font-size-22"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Tenant Payment</h5>
                        <p class="text-muted mb-0">${{ Number(property.parking).toLocaleString() }}</p>
                      </div>
                    </div>
                  </div>


                  <div class="col-lg-3 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i class="uil uil-check-circle text-primary font-size-22"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Other</h5>
                        <p class="text-muted mb-0">${{ Number(property.other_income).toLocaleString() }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end row -->

                <div class="row">
                  <div class="col-lg-3 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i class="uil uil-calendar-alt text-primary font-size-22"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Start Date</h5>
                        <p class="text-muted mb-0">{{ property.start_date }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                  <div class="col-lg-3 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i class="uil uil-check-circle text-primary font-size-22"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Tenant Type</h5>
                        <p class="text-muted mb-0">{{ property.t_payment }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- end col-->
                </div>
                <!-- end row -->


                <div class="row">
                  <div class="col-lg-3 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i class="uil uil-calendar-alt text-primary font-size-22"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Last Payment</h5>
                        <p class="text-muted mb-0">{{ property.last_payment_month }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->


                  <div class="col-lg-3 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i class="uil uil-calendar-alt text-primary font-size-22"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Status</h5>
                        <p class="text-muted mb-0">{{ property.pm_status }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->


                  <div class="col-lg-3 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i class="uil uil-calendar-alt text-primary font-size-22"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Terminate Date</h5>
                        <p class="text-muted mb-0">{{ property.term_date }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->


                </div>
                <!-- end row -->


              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
</template>